import React, { useState, useRef, useCallback, useEffect } from "react";
import { Container, Modal, Button, Badge } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./FGallery.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const images = [
  {
    src: require("../Pages/Images/gal28.jpg"),
    alt: "Хирург и Я фотосессия",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal27.jpg"),
    alt: "Хирург и Я фотосессия",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal26.jpg"),
    alt: "Хирург и Я фотосессия",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news38.jpg"),
    alt: "Мейлах Борис Львович и Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news37.jpg"),
    alt: "Шушанов Антон Павлович и Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal22.jpg"),
    alt: "Сабадаш Андрей Владимирович и Камилов Магомед Юсупович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal23.jpg"),
    alt: "Шушанов Антон Павлович и Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal24.jpg"),
    alt: "Шушанов Антон Павлович и Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal25.jpg"),
    alt: "Сабадаш Андрей Владимирович и администратор Анна",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/snews8.jpg"),
    alt: "YCELLBIO-KIT",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news35.jpg"),
    alt: "Сабадаш Андрей Владимирович и администратор Анна",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/snews7.mp4"),
    type: "video",
    alt: "Video 4",
    poster: require("../Pages/Images/News/snews7.jpg"),
    badge: "Видео",
  },
  {
    src: require("../Pages/Images/News/snews6.jpg"),
    alt: "Image 28",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news33.jpg"),
    alt: "Image 27",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news32.jpg"),
    alt: "Image 26",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news31.jpg"),
    alt: "Image 25",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news25.jpg"),
    alt: "Image 20",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news27.jpg"),
    alt: "Image 22",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news28.jpg"),
    alt: "Image 23",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news29.jpg"),
    alt: "Image 23",
    type: "image",
  },
  {
    src: require("../Pages/Images/News/news30.jpg"),
    alt: "Image 24",
    type: "image",
  },
  {
    src: require("../Pages/Videos/galvideo3.mp4"),
    type: "video",
    alt: "Video 3",
    poster: require("../Pages/Images/videoposter3.png"),
    badge: "Видео",
  },
  {
    src: require("../Pages/Images/gal21.jpg"),
    alt: "Image 21",
    type: "image",
  },
  {
    src: require("../Pages/Videos/galvideo1.mp4"),
    type: "video",
    alt: "Хирург и Я вид с улицы видео",
    poster: require("../Pages/Images/videoposter1.png"),
    badge: "Видео",
  },
  {
    src: require("../Pages/Images/News/news40.jpg"),
    alt: "Хирург и Я препараты",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal20.jpg"),
    alt: "Image 3",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal4.jpg"),
    alt: "Хирург и Я команда",
    type: "image",
  },
  {
    src: require("../Pages/Videos/galvideo2.mp4"),
    type: "video",
    alt: "Хирург и Я видео операции",
    poster: require("../Pages/Images/videoposter2.png"),
    badge: "Видео",
  },
  {
    src: require("../Pages/Images/gal5.jpg"),
    alt: "Хирург и Я вид с улицы",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal6.jpg"),
    alt: "Image 6",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal7.jpg"),
    alt: "Image 7",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal8.jpg"),
    alt: "Хирург и Я приемная",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal9.jpg"),
    alt: "Сабадаш Андрей Владимирович",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal10.jpg"),
    alt: "Сабадаш Андрей Владимирович интервью",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal13.jpg"),
    alt: "Хирург и Я аптечка",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal14.jpg"),
    alt: "Хирург и Я зал",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal16.jpg"),
    alt: "Хирург и Я кабинет",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal17.jpg"),
    alt: "Хирург и Я",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal18.jpg"),
    alt: "Хирург и Я",
    type: "image",
  },
  {
    src: require("../Pages/Images/gal19.jpg"),
    alt: "Благодарность Хирург и Я",
    type: "image",
  },
];

export default function FGallery() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const carouselRef = useRef(null); // Ref

  const openModal = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  const handleNext = useCallback(() => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  }, [currentIndex]);

  const handlePrevious = useCallback(() => {
    const prevIndex =
      (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
  }, [currentIndex]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrevious();
      }
    };

    // Добавить слушателя событий
    window.addEventListener("keydown", handleKeyDown);

    // Отчистисть слушателя событий
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleNext, handlePrevious]); // Влючаем зависимости

  return (
    <>
      <div className="gallery" ref={carouselRef}>
        <Container className="container-gallery-layout mt-5">
          <div className="row gy-4">
            {images.map((image, index) => (
              <div key={index} className="col-md-3 position-relative">
                {image.type === "image" ? (
                  <LazyLoadImage
                    src={image.src}
                    alt={image.alt}
                    effect="blur"
                    className={`img-fluid rounded-lg rounded gallery-image ${
                      currentIndex === index ? "active" : ""
                    }`}
                    placeholderSrc="https://via.placeholder.com/300x300"
                    onClick={() => openModal(index)}
                  />
                ) : (
                  <img
                    src={image.poster} // Использовать poster для видео
                    alt={image.alt}
                    className={`img-fluid rounded-lg rounded gallery-image ${
                      currentIndex === index ? "active" : ""
                    }`}
                    onClick={() => openModal(index)}
                  />
                )}
                <Badge className="gallery-image-badge position-absolute">
                  {image.badge}
                </Badge>
              </div>
            ))}
          </div>
        </Container>
      </div>

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body>
          {images[currentIndex] && ( // 'images' существует?
            images[currentIndex].type === "video" ? (
              <ReactPlayer url={images[currentIndex].src} controls width="100%" height="100%" />
            ) : (
              <img
                src={images[currentIndex].src} // Получить изображение основываясь на currentIndex
                alt={images[currentIndex].alt}
                className="img-fluid"
              />
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="gallery-controls">
            <Button variant="primary" className="me-2" onClick={handlePrevious}>
              Предыдущее
            </Button>
            <Button variant="primary" onClick={handleNext}>
              Следующее
            </Button>
          </div>
          <Button variant="secondary" onClick={closeModal}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}